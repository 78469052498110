<template>
    <div class="w-100">
        <vs-select
            v-if="enabled"
            v-model="option"
            :state="stateInputDark"
            label="Tipo de identificación"
            class="mw-100 border-custom opacity-false"
            @input="onInput"
        >
            <vs-option
                v-for="(item, key) in $data.$enabledOptions"
                :key="key"
                :label="item"
                :value="key"
            >
                {{ item }}
            </vs-option>
        </vs-select>
        <div v-else>
            <vs-input
                v-model="value"
                border
                shadow
                class="w-100 opacity-false"
                label-placeholder="Tipo de identificación"
                disabled
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { identificationTypes } from "@/common/fields/identification-types";

export default {
    name: "IdentificationTypeSelector",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            type: String,
            default: () => "cc",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "cc",
        value: identificationTypes.cc,
        $enabledOptions: identificationTypes
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemCurrent(value) {
            this.option = value;
        }
    },
    mounted() {
        this.option = this.itemCurrent;
    },
    methods: {
        onInput(value) {
            this.value = identificationTypes[value];
            this.$emit("change", value);
        }
    }
};
</script>
