<template>
    <div>
        <div class="text-center">
            <h1>Registrar cliente</h1>
        </div>
        <create-customer v-if="showForm" @create="createCustomerComponent" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CreateCustomer from "@/components/customers/CreateCustomer.vue";

export default {
    name: "NewCustomer",
    components: {
        CreateCustomer
    },
    data: () => ({ loaderInstance: null, showForm: true, id: "" }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    methods: {
        ...mapActions("customers", ["createCustomer"]),
        async createCustomerComponent(user) {
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createCustomer(user);
                this.id = data.id;
                this.showForm = false;
                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Cliente creado con el Id: ${data.id}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de clientes",
                    cancelButtonText: "Registrar cliente"
                });

                if (dialogResult.isConfirmed) {
                    this.id = data.id;
                    this.$router.push(`/app/customers/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/customers/");
                }
                if (dialogResult.isDismissed) {
                    this.showForm = true;
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        }
    }
};
</script>
