<template>
    <div class="w-100">
        <vs-select
            v-if="enabled"
            v-model="option"
            filter
            collapse-chips
            :state="stateInputDark"
            class="w-100 mw-100 border-custom opacity-false"
            :label="label"
            name="codeCity"
            @input="onInput"
        >
            <vs-option
                v-for="(item, key) in $data.$enabledOptions"
                :key="key"
                :label="item"
                :value="key"
            >
                {{ item }}
            </vs-option>
        </vs-select>
        <vs-input
            v-else
            v-model="value"
            border
            shadow
            class="w-100 opacity-false"
            :label-placeholder="label"
            disabled
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { citiesCodes } from "@/common/fields/cities-codes";

export default {
    name: "CitiesCodesSelector",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: String,
            default: () => "",
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        label: {
            default: () => "Ciudad",
            type: String,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "1101",
        value: "Bogotá",
        $enabledOptions: citiesCodes
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"])
    },
    watch: {
        itemsCurrent(value) {
            this.value = citiesCodes[value];
            this.option = value;
        }
    },
    mounted() {
        this.value = citiesCodes[this.itemsCurrent];
        this.option = this.itemsCurrent;
    },
    methods: {
        onInput(value) {
            this.value = citiesCodes[value];
            this.$emit("change", value);
        }
    }
};
</script>
